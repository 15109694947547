<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="12" sm="9" :class="$vuetify.breakpoint.xsOnly && 'mb-2'">
        <div class="d-flex align-center flex-wrap">
          <v-autocomplete
            v-model="idClass"
            :items="dataClassList"
            :item-text="concatString"
            :label="$t('master_data.student.tab1_data.choose_class')"
            :loading="loadingClass"
            item-value="id"
            hide-details
            clearable
            outlined
            dense
            class="mr-2 select-200"
            @change="changeClass"
          />
          <div class="subtitle-2 font-weight-bold">
            {{ dataStudent.length }} {{ $t("master_data.student.student") }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          :label="$t('app.search')"
          :disabled="!idClass ? true : false"
          outlined
          dense
          hide-details
          prepend-inner-icon="mdi-magnify"
          style="max-width: 250px"
          @keyup.enter="handleSearch()"
        />
      </v-col>
    </v-row>

    <v-divider class="my-3" />

    <v-data-table
      :headers="tableHeaders"
      :items="dataStudent"
      :loading="loading"
      :sort-by="queryData.sort"
      item-key="student"
      multi-sort
      class="elevation-0"
    >
      <!-- :page.sync="queryData.page"
      :items-per-page="queryData.limit" -->
      <template v-slot:item.action="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
              small
              class="mx-2 primary"
              @click="showDialog(item)"
            >
              <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("personalization.edit_uid") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <Dialog
      :model="modelDialog"
      :isStudent="true"
      :listData="dataStudent"
      :currentIndex="selectedIndex"
      @isOpen="isDialogClass"
      @getData="getData"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getActiveClass } from "@/api/admin/information";
import { studentsList } from "@/api/admin/master/student";
import i18n from "@/i18n";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    init: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Dialog: () => import("./Dialog")
  },
  data() {
    return {
      idClass: null,
      dataStudent: [],
      loading: false,
      tableHeaders: [
        {
          text: i18n.t("personalization.table.name"),
          align: "left",
          value: "name",
          width: 180
        },
        { text: i18n.t("app.grade"), value: "grade" },
        { text: i18n.t("personalization.table.class"), value: "class_name" },
        { text: i18n.t("app.nis"), value: "nis", width: 100 },
        { text: i18n.t("personalization.table.uid"), value: "uid", width: 150 },
        {
          text: i18n.t("personalization.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataClassList: [],
      search: "",
      queryData: {
        sort: ["grade", "class_name", "nis"],
        order: "ASC",
        search: "",
        institution: "",
        filter: { "class_students.class": [] }
      },
      modelDialog: false,
      loadingClass: false,
      selectedIndex: 0
    };
  },
  watch: {
    init: {
      immediate: true,
      handler() {
        this.getData();
      }
    },
    "queryData.page": {
      handler() {
        this.getData();
      },
      deep: true
    },
    "queryData.limit": {
      handler() {
        this.getData();
      },
      deep: true
    },
    search(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.search = value;
        this.getData();
      }, doneTypingInterval);
    }
  },
  computed: mapGetters(["g_master_data_siswa"]),
  created() {
    this.getClass();
  },
  methods: {
    handleSearch() {
      // check if UID searching
      if (Number(this.search).toString().length === 10) {
        const hexa = Number(this.search)
          .toString(16)
          .match(/.{1,2}/g)
          .reverse()
          .join("")
          .toUpperCase();
        this.search = hexa;
        this.queryData.search = hexa;
      } else {
        this.queryData.search = this.search;
      }
      this.getData();
    },
    async getClass() {
      this.loadingClass = true;
      try {
        const response = await getActiveClass();
        if (response.data.code) this.dataClassList = response.data.data;
      } catch (error) {
        console.log("getClass()", error);
      }
      this.loadingClass = false;
    },
    changeClass(id) {
      this.queryData.filter["class_students.class"] = id ? [id] : [];
      this.getData();
    },
    concatString: item => item.grade + " / " + item.name,
    getData() {
      this.dataStudent = [];
      this.loading = true;
      studentsList(this.queryData)
        .then(res => {
          const r = res.data;
          this.dataStudent = r.data ?? [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    isDialogClass() {
      this.modelDialog = !this.modelDialog;
    },
    showDialog(data) {
      this.selectedIndex = this.dataStudent.indexOf(data);
      this.modelDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select__slot {
  .v-label {
    font-size: 14px;
  }
}
</style>
